<template>
    <draggable
        class="flex flex-col"
        handle=".handle"
        :options="draggableOptions"
        @start="drag = true"
        @end="drag = false"
        v-model="draggableItems"
    >
        <activix-tooltip
            placement="left-start"
            :content="nonValidLabel(item)"
            :disabled="itemIsValid(item) || !nonValidLabel(item)"
            :key="item.id"
            v-for="(item, index) in draggableItems"
        >
            <li
                class="p-1 border border-grey-300 bg-grey-200 rounded-sm flex items-center"
                :class="[itemClass(item), { 'mt-2': index !== 0 }]"
            >
                <div
                    class="handle | p-2 flex items-center"
                    :class="[{ 'cursor-move': itemIsValid(item) || !nonValidLabel(item) }]"
                >
                    <icon class="text-grey-600" name="regular/navigation-menu" slot="icon" />
                </div>
                <div class="flex-1 flex justify-between items-center">
                    <div>
                        <slot name="label" :item="item">
                            {{ item[itemLabel] }}
                        </slot>
                    </div>
                    <div class="flex items-center">
                        <slot name="actions" :item="item" />
                        <span
                            class="link-grey | opacity-50 font-bold text-xl p-1 leading-none"
                            @click="onRemove(item.id)"
                            v-if="removable"
                        >
                            &times;
                        </span>
                    </div>
                </div>
            </li>
        </activix-tooltip>
    </draggable>
</template>

<script>
    // Libraries
    import { isEqual } from 'lodash-es';
    import Draggable from 'vuedraggable';

    export default {
        components: {
            Draggable,
        },

        props: {
            name: {
                type: String,
                required: true,
            },
            items: {
                type: Array,
                default: () => [],
            },
            itemLabel: {
                type: String,
                default: 'name',
            },
            sortable: {
                type: Boolean,
                default: true,
            },
            editable: {
                type: Boolean,
                default: true,
            },
            removable: {
                type: Boolean,
                default: false,
            },
            putable: {
                type: Boolean,
                default: true,
            },
            pullable: {
                type: Boolean,
                default: true,
            },
            itemIsValid: {
                type: Function,
                default: () => true,
            },
            nonValidLabel: {
                type: Function,
                default: () => 'Invalid',
            },
        },

        data() {
            return {
                draggableItems: [],
            };
        },

        computed: {
            hasItems() {
                return this.draggableItems.length > 0;
            },

            draggableOptions() {
                return {
                    filter: '.not-draggable',
                    disable: !this.editable,
                    ghostClass: this.sortable ? 'item-ghost' : 'item-ghost-none',
                    sort: this.sortable,
                    group: { name: this.name, pull: this.pullable, put: this.putable },
                };
            },
        },

        watch: {
            items: {
                immediate: true,
                handler() {
                    this.draggableItems = this.items;
                },
            },

            draggableItems() {
                this.onChange();
            },
        },

        methods: {
            itemClass(item) {
                if (!this.itemIsValid(item)) {
                    return ['not-draggable', 'cursor-not-allowed', 'opacity-50'];
                }

                return ['draggable', 'cursor-default'];
            },

            onRemove(id) {
                const removedItem = this.draggableItems.find(item => item.id === id);

                this.draggableItems.some((item, index) => {
                    return item.id === id ? !!this.draggableItems.splice(index, 1) : false;
                });

                this.$emit('remove', removedItem);
            },

            onChange() {
                if (!isEqual(this.draggableItems, this.items)) {
                    this.$emit('update', this.draggableItems);
                }
            },
        },
    };
</script>

<style>
    .items-transition-move {
        transition: transform 0.4s;
    }
    .item-ghost {
        opacity: 0.3;
    }
    .item-ghost-none {
        display: none !important;
    }
</style>
