<template>
    <div class="box | flex flex-col">
        <div class="box-header | h-16 border-b">
            <div class="flex items-center justify-between | h-full">
                <div class="flex items-center">
                    <h4 class="box-title">
                        {{ $tc('flows.user', 1) }}
                    </h4>
                    <router-link class="ml-2" :to="{ name: 'users.index', query: { accountId: contextAccount.id } }">
                        <icon class="text-sm" :name="$icons.add" />
                    </router-link>
                </div>
                <activix-input
                    class="max-w-64"
                    input-class="input-sm"
                    :placeholder="$t('search.search')"
                    v-model="searchUser"
                />
            </div>
        </div>
        <div class="box-body | flex-1 flex flex-col h-1 p-0">
            <div class="overflow-y-auto">
                <activix-draggable-list
                    class="p-6"
                    name="advisors"
                    :items="filteredUsers"
                    :item-is-valid="hasServiceAccess"
                    :non-valid-label="listInvalidLabel"
                    @update="onUpdate"
                >
                    <template #actions="{ item }">
                        <activix-tooltip
                            :content="$t('flows.hasDuplicateAlert')"
                            v-if="onLeadXpress && hasDuplicateAlert(item)"
                        >
                            <icon name="bold/subtract-circle" class="text-grey-500 bg-white rounded-full" />
                        </activix-tooltip>
                    </template>
                </activix-draggable-list>
            </div>
        </div>
    </div>
</template>

<script>
    import { latinize } from '@/utils/string.js';
    import { mapState, mapWritableState } from 'pinia';
    import ActivixDraggableList from '../elements/ActivixDraggableList.vue';
    import { useFlowStore } from '../../store/modules/flow/store.js';
    import { useContextStore } from '../../store/modules/context/store.js';

    export default {
        components: {
            ActivixDraggableList,
        },

        data() {
            return {
                searchUser: '',
            };
        },

        computed: {
            ...mapWritableState(useFlowStore, {
                unassignedAdvisors: 'unassigned',
            }),
            ...mapState(useContextStore, {
                contextAccount: 'account',
            }),
            ...mapState(useFlowStore, {
                flowService: 'service',
            }),

            onLeadXpress() {
                return this.flowService == 'leadxpress';
            },

            filteredUsers() {
                return this.unassignedAdvisors
                    .filter(user => !user.hide_in_user_select && this.userInSearch(user))
                    .sort((a, b) => a.name.localeCompare(b.name));
            },
        },

        methods: {
            onUpdate(list) {
                useFlowStore().unassigned = list;
            },

            hasServiceAccess(item) {
                return item.service_access;
            },

            hasDuplicateAlert(item) {
                return item.notification_new_lead_duplicate;
            },

            listInvalidLabel() {
                return this.$t('flows.noService', { service: this.$t(`flows.services.${this.flowService}`) });
            },

            userInSearch(user) {
                const name = latinize(user.name.toLowerCase());
                const search = latinize(this.searchUser.toLowerCase());

                return empty(this.searchUser) || name.includes(search);
            },
        },
    };
</script>
