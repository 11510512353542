<template>
    <div class="p-4 rounded-sm">
        <h4>{{ title }}</h4>
        <p class="italic" v-if="items.length === 0">
            {{ $t('flows.emptyPriority') }}
        </p>
        <activix-draggable-list
            name="advisors"
            :items="items"
            :removable="true"
            @update="onUpdate"
            @remove="onRemove"
        >
            <template #actions="{ item }">
                <activix-tooltip
                    :content="$t('flows.hasDuplicateAlert')"
                    v-if="onLeadXpress && hasDuplicateAlert(item)"
                >
                    <icon name="bold/subtract-circle" class="text-grey-500 bg-white rounded-full" />
                </activix-tooltip>
            </template>
        </activix-draggable-list>
    </div>
</template>

<script>
    import { cloneDeep } from 'lodash-es';

    // Components
    import { mapWritableState, mapState } from 'pinia';
    import ActivixDraggableList from '../elements/ActivixDraggableList.vue';
    import { useFlowStore } from '../../store/modules/flow/store.js';

    export default {
        components: {
            ActivixDraggableList,
        },

        props: {
            items: {
                type: Array,
                default: () => [],
            },
            order: {
                type: Number,
                default: 1,
            },
        },

        computed: {
            ...mapWritableState(useFlowStore, {
                priorizedAdvisors: 'priorized',
                unassignedAdvisors: 'unassigned',
            }),

            ...mapState(useFlowStore, {
                flowService: 'service',
            }),

            title() {
                return `${this.$tc('flows.priority', 1)} #${this.order + 1}`;
            },

            onLeadXpress() {
                return this.flowService == 'leadxpress';
            },
        },

        methods: {
            onUpdate(items) {
                this.$set(this.priorizedAdvisors, this.order, items);
            },

            onRemove(element) {
                const unassigned = cloneDeep(this.unassignedAdvisors);
                unassigned.push(element);

                this.unassignedAdvisors = unassigned;
            },

            hasDuplicateAlert(item) {
                return item.notification_new_lead_duplicate;
            },
        },
    };
</script>
