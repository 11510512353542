<template>
    <activix-modal :opened="opened" :closable="false">
        <template slot="body">
            <sweet-alert-icon class="mx-auto" type="warning" />
            <div class="text-lg text-center" v-text="$t('flows.selectDivision')" />
        </template>

        <template slot="footer">
            <div class="flex -mx-2">
                <activix-button class="flex-1 mx-2" @click="onChoose('new')">
                    {{ $t('flows.new') }}
                </activix-button>
                <activix-button class="flex-1 mx-2" @click="onChoose('used')">
                    {{ $t('flows.used') }}
                </activix-button>
                <activix-button class="flex-1 mx-2" @click="onChoose('finance')" v-if="showCredit">
                    {{ $t('flows.credit') }}
                </activix-button>
                <activix-button class="flex-1 mx-2" @click="onChoose('service')" v-if="showService">
                    {{ $t('flows.service') }}
                </activix-button>
            </div>
        </template>
    </activix-modal>
</template>

<script>
    import { mapActions, mapState } from 'pinia';
    import Division from '../../entities/Division.js';
    import SweetAlertIcon from '../elements/SweetAlert/Icon.vue';
    import { useContextStore } from '../../store/modules/context/store.js';

    export default {
        components: {
            SweetAlertIcon,
        },

        props: {
            type: {
                type: String,
                required: true,
            },
            opened: {
                type: Boolean,
                required: true,
            },
        },

        computed: {
            ...mapState(useContextStore, {
                contextAccount: 'account',
            }),

            hasCredit() {
                return this.contextAccount.credit || false;
            },

            hasService() {
                return this.contextAccount.service || false;
            },

            showCredit() {
                return (
                    this.hasCredit &&
                    (this.type == 'schedule' || (this.type == 'flow' && this.$route.name != 'flow.phoneUp'))
                );
            },

            showService() {
                return this.hasService;
            },
        },

        methods: {
            ...mapActions(useContextStore, ['setContextDivisionId']),

            onChoose(division) {
                this.setContextDivisionId(Division.getByName(division));

                this.close();
            },

            close() {
                this.$emit('update:opened', false);
            },
        },
    };
</script>
