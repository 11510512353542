<template>
    <el-dropdown class="max-w-full" placement="bottom-start" trigger="click">
        <activix-button class="flex items-center max-w-full" type="outline">
            <h1
                class="m-0 truncate text-xl font-semibold text-grey-650"
                :class="{ 'ml-4': lgLayout }"
                v-text="pageTitle"
            />
            <icon class="text-grey-600 ml-4 flex-shrink-0 text-xs" :name="$icons.expand" />
        </activix-button>
        <el-dropdown-menu class="max-w-full" slot="dropdown">
            <el-dropdown-item class="group" :key="index" v-for="(flowNav, index) in flowNavs">
                <div class="flex items-baseline">
                    <router-link
                        class="flex-1 pl-2 pr-4 truncate"
                        :class="flowNav.isActive ? 'text-blue-500 font-bold' : 'text-inherit'"
                        :to="{ name: flowNav.route }"
                    >
                        {{ flowNav.title }}
                    </router-link>
                </div>
            </el-dropdown-item>
        </el-dropdown-menu>
    </el-dropdown>
</template>

<script>
    import { mapState } from 'pinia';
    import { useGlobalStore } from '../store/store.js';

    export default {
        computed: {
            ...mapState(useGlobalStore, ['authUser', 'pageTitle']),

            flowNavs() {
                const availableFlows = [];

                if (this.authUser.hasAccessTo('flow.webBoost')) {
                    availableFlows.push({
                        title: this.$t('navs.sidebar.webBoost'),
                        route: 'flow.webBoost',
                        isActive: this.$route.name === 'flow.webBoost',
                    });
                }

                if (this.authUser.hasAccessTo('flow.phoneUp')) {
                    availableFlows.push({
                        title: this.$t('navs.sidebar.phoneUp'),
                        route: 'flow.phoneUp',
                        isActive: this.$route.name === 'flow.phoneUp',
                    });
                }

                if (this.authUser.hasAccessTo('flow.leadXpress')) {
                    availableFlows.push({
                        title: this.$t('navs.sidebar.leadXpress'),
                        route: 'flow.leadXpress',
                        isActive: this.$route.name === 'flow.leadXpress',
                    });
                }

                return availableFlows;
            },
        },
    };
</script>
