<template>
    <div class="box | flex flex-col overflow-hidden">
        <div class="box-header | h-16 border-b">
            <div class="flex items-center justify-between | h-full">
                <h4 class="box-title">
                    {{ $tc('flows.priority', 0) }}
                </h4>
                <div class="flex items-center">
                    <small class="text-grey-600 | ml-4 mt-1" v-if="isLeadXpressFlow">
                        {{ $t('flows.leadxpressRequiresContact') }}
                    </small>
                    <activix-tooltip :content="$t('flows.notifyPrecedentPriorities')" class="ml-6">
                        <activix-switcher
                            :value="notifyPrecedentPriorities"
                            @input="modifyNotifyPrecedentPriorities($event)"
                        />
                    </activix-tooltip>
                </div>
            </div>
        </div>
        <div class="box-body | flex-1 flex flex-col h-1 p-0">
            <div class="overflow-y-auto">
                <div class="flex flex-col m-6 border border-grey-200 divide-y divide-gray-200">
                    <priority-slot
                        :order="index"
                        :items="advisors"
                        :key="index"
                        v-for="(advisors, index) in priorizedAdvisors"
                    />
                </div>
            </div>
        </div>
        <div class="box-footer | p-0">
            <div class="m-4 text-center">
                <activix-tooltip :content="addPriorityTooltip">
                    <span>
                        <activix-button type="primary" :disabled="cantAdd" @click="onAdd">
                            {{ $t('flows.addPriority') }}
                        </activix-button>
                    </span>
                </activix-tooltip>
                <activix-button
                    class="ml-2"
                    type="primary"
                    :disabled="cantDelete"
                    @click="onDelete"
                >
                    {{ $t('flows.deletePriority') }}
                </activix-button>
            </div>
        </div>
    </div>
</template>

<script>
    // Library
    import { clone } from 'lodash-es';

    // Components
    import { mapState, mapWritableState } from 'pinia';
    import PrioritySlot from './PrioritySlot.vue';

    // pinia
    import { useFlowStore } from '../../store/modules/flow/store.js';
    import { useContextStore } from '../../store/modules/context/store.js';
    import { useGlobalStore } from '../../store/store.js';

    export default {
        components: {
            PrioritySlot,
        },

        computed: {
            ...mapState(useGlobalStore, ['pageTitle']),
            ...mapState(useContextStore, {
                contextAccount: 'account',
            }),
            ...mapWritableState(useFlowStore, {
                priorizedAdvisors: 'priorized',
                unassignedAdvisors: 'unassigned',
                notifyPrecedentPriorities: 'notifyPrecedentPriorities',
            }),
            ...mapState(useFlowStore, {
                currentFlowId: 'currentFlowId',
            }),

            addPriorityTooltip() {
                if (this.isInfiniteLeadXpressFlow && this.priorizedAdvisors.length > 0) {
                    return this.$t('accounts.edit.infinitePriorityWarning');
                }

                if (this.priorizedAdvisors.length > 4) {
                    return this.$t('flows.maxPriority');
                }

                return '';
            },

            cantAdd() {
                return this.priorizedAdvisors.length > 4 ||
                    this.isInfiniteLeadXpressFlow && this.priorizedAdvisors.length > 0;
            },

            cantDelete() {
                return this.priorizedAdvisors.length < 2;
            },

            isInfiniteLeadXpressFlow() {
                return this.isLeadXpressFlow &&
                    this.contextAccount.leadxpress_option.priority === 9999999;
            },

            isLeadXpressFlow() {
                return this.$route.name === 'flow.leadXpress';
            },
        },

        methods: {
            onAdd() {
                const priorized = clone(this.priorizedAdvisors);

                priorized.push([]);

                this.priorizedAdvisors = priorized;
            },

            onDelete() {
                const priorized = clone(this.priorizedAdvisors);
                const deletedItems = priorized.pop();

                this.priorizedAdvisors = priorized;

                const unassigned = clone(this.unassignedAdvisors);
                unassigned.push(...deletedItems);

                this.unassignedAdvisors = unassigned;
            },

            modifyNotifyPrecedentPriorities(event) {
                this.notifyPrecedentPriorities = event;
                this.saveFlow();
            },

            async saveFlow() {
                this.$wait.start('updating.flows');

                try {
                    await this.$api.flows.update(this.currentFlowId, {
                        unassigned: this.unassignedAdvisors,
                        priorized: this.priorizedAdvisors,
                        notifyPrecedentPriorities: this.notifyPrecedentPriorities,
                    });

                    this.$wait.start('updating.flows');
                } catch (error) {
                    this.$wait.start('updating.flows');
                    this.$notify.error(this.$t('flows.alerts.update.error'));

                    throw error;
                }
            },
        },
    };
</script>
