<template>
    <div class="flex-1 flex space-x-6">
        <priority-panel class="h-screen-80 flex-1" :class="{ loading: isLoading }" />
        <user-list-panel class="h-screen-80 flex-1" :class="{ loading: isLoading }" />
    </div>
</template>

<script>
    import { mapWritableState, mapState } from 'pinia';
    import PriorityPanel from './PriorityPanel.vue';
    import UserListPanel from './UserListPanel.vue';
    import { useFlowStore } from '../../store/modules/flow/store.js';

    export default {
        components: {
            UserListPanel,
            PriorityPanel,
        },

        props: {
            advisors: {
                type: Array,
                default: () => [],
            },
            isLoading: {
                type: Boolean,
                default: false,
            },
        },

        data() {
            return {
                priorities: [0, 1, 2, 3, 4],
            };
        },

        computed: {
            ...mapWritableState(useFlowStore, {
                priorizedAdvisors: 'priorized',
                unassignedAdvisors: 'unassigned',
            }),
            ...mapState(useFlowStore, {
                currentFlowId: 'currentFlowId',
                flowService: 'service',
                notifyPrecedentPriorities: 'notifyPrecedentPriorities',
            }),
        },

        watch: {
            advisors: {
                immediate: true,
                handler() {
                    this.buildUnassigned();
                    this.buildAssigned();
                },
            },

            priorizedAdvisors(newValue, oldValue) {
                let update = true;
                newValue.forEach((flow, index) => {
                    flow.forEach(newFlowUser => {
                        oldValue.forEach((oldFlow, oldIndex) => {
                            if (oldIndex != index && oldFlow.some(oldFlowUser => oldFlowUser.id == newFlowUser.id)) {
                                update = false;
                            }
                        });
                    });
                });

                if (update) {
                    this.saveFlow();
                }
            },

            unassignedAdvisors() {
                this.saveFlow();
            },
        },

        methods: {
            buildUnassigned() {
                useFlowStore().unassigned = this.advisors.filter(advisor => !advisor.order);
            },

            buildAssigned() {
                const assigned = this.advisors.filter(advisor => advisor.order);
                let priorized = [];
                let highestSlot = 0;

                this.priorities.forEach(value => {
                    priorized[value] = assigned.filter(advisor => {
                        const order = Number.parseInt(Number.parseFloat(advisor.order, 10), 10) - 1;
                        highestSlot = order > highestSlot ? order : highestSlot;
                        return order === value;
                    });
                });

                priorized = priorized.filter((slot, index) => {
                    return index <= highestSlot;
                });

                if (priorized.length < 1) {
                    priorized.push([]);
                }

                this.priorizedAdvisors = priorized;
            },

            async saveFlow() {
                this.$wait.start('updating.flows');
                try {
                    await this.$api.flows.update(this.currentFlowId, {
                        unassigned: this.unassignedAdvisors.length !== 0 ? this.unassignedAdvisors : [[]],
                        priorized: this.priorizedAdvisors,
                    });

                    this.$wait.start('updating.flows');
                } catch (error) {
                    this.$wait.start('updating.flows');
                    this.$notify.error(this.$t('flows.alerts.update.error'));

                    throw error;
                }
            },
        },
    };
</script>
