<template>
    <div class="flex flex-col">
        <warning
            icon="regular/phone"
            :title="$t('error.warning')"
            :content="$t('error.selectAccountWarning')"
            v-if="!validAccount"
        />
        <template v-else>
            <portal to="content-header">
                <flow-selector />
            </portal>

            <portal to="content-header-left">
                <activix-reload :loading="isLoading" @click="fetchFlow" />
            </portal>

            <flow-manager :advisors="advisors" :is-loading="isLoading" v-if="validDivision" />

            <select-division type="flow" :opened.sync="selectDivisionOpened" />
        </template>
    </div>
</template>

<script>
    import { get } from 'lodash-es';

    // Mixins
    import { mapState } from 'pinia';
    import Flows from '../../mixins/Flows.js';
    import TrackView from '../../mixins/TrackView.js';

    // Component
    import FlowManager from '../../components/flow/FlowManager.vue';
    import SelectDivision from '../../components/modals/SelectDivision.vue';
    import Warning from '../../components/Warning.vue';
    import FlowSelector from '../../components/FlowSelector.vue';

    // Pinia
    import { useFlowStore } from '../../store/modules/flow/store.js';
    import { useContextStore } from '../../store/modules/context/store.js';

    export default {
        components: {
            Warning,
            SelectDivision,
            FlowManager,
            FlowSelector,
        },

        mixins: [Flows, TrackView],

        data() {
            return {
                advisors: [],
                selectDivisionOpened: false,
            };
        },

        computed: {
            ...mapState(useContextStore, {
                contextChildAccount: 'contextChildAccount',
                contextAccount: 'account',
                contextDivisionId: 'divisionId',
            }),
            ...mapState(useFlowStore, {
                flowService: 'service',
            }),

            isLoading() {
                return this.$wait.is(['fetching.contextAccount', 'fetching.flows']);
            },

            validAccount() {
                return get(this.contextChildAccount, 'id');
            },

            validDivision() {
                return this.$route.meta.header.division.includes(this.contextDivisionId);
            },
        },

        watch: {
            'contextAccount.id'() {
                if (this.validDivision) {
                    this.fetchFlow();
                }
            },

            contextDivisionId() {
                if (this.validDivision) {
                    this.fetchFlow();
                } else {
                    this.selectDivisionOpened = true;
                }
            },
        },

        methods: {
            async fetchFlow() {
                this.$wait.start('fetching.flows');

                try {
                    const response = await this.$api.flows.show(this.contextAccount.id, {
                        type: this.flowService,
                        division: this.contextDivisionId,
                    });

                    this.advisors = response.advisors;

                    useFlowStore().currentFlowId = response.flowId;
                    useFlowStore().notifyPrecedentPriorities = response.notifyPrecedentPriorities;
                    this.$wait.end('fetching.flows');
                } catch (error) {
                    this.$wait.end('fetching.flows');
                    this.$notify.error(this.$t('flows.alerts.show.error'));

                    throw error;
                }
            },
        },

        created() {
            useFlowStore().service = 'phoneup';

            if (this.validDivision) {
                this.fetchFlow();
            } else {
                this.$nextTick(() => {
                    this.selectDivisionOpened = true;
                });
            }
        },
    };
</script>
